<template>
<vx-card no-shadow class="card-overflow" title="Vehicle Registration" :customTitle="car.registrationPlate">
    <template slot="actions">
        <vs-button @click="goBack" color="dark">Back</vs-button>
    </template>
    <vs-divider />
    <div title="Vehicle Registration Update" icon="ti-car" size="small" class="mb-base">
        <form>
            <div class="vx-col w-full">
                <div class="vx-row mb-2">
                    <div class="vx-col sm:w-1/3 w-full">
                        <span class="text-right">State of Registration:</span>
                    </div>
                    <div class="vx-col sm:w-1/3 w-full">
                        <vs-input :disabled="true" v-model="car.registrationState" name="state" class="w-full">
                        </vs-input>
                    </div>
                </div>
                <div class="vx-row mb-2">
                    <div class="vx-col sm:w-1/3 w-full">
                        <span class="text-right">Registration Plate:</span>
                    </div>
                    <div class="vx-col sm:w-1/3 w-full">
                        <vs-input class="w-full" name="licencePlate" :disabled="true" v-model="car.registrationPlate" />
                    </div>
                </div>
                <div class="vx-row mb-2">
                    <div class="vx-col sm:w-1/3 w-full">
                        <span class="text-right">Vehicle Name/Model:</span>
                    </div>
                    <div class="vx-col sm:w-1/3 w-full">
                        <vs-input class="w-full" v-model="car.model" />
                    </div>
                </div>

                <div class="vx-row mb-2">
                    <div class="vx-col sm:w-1/3 w-full">
                        <span class="text-right">Registration Expires:</span>
                    </div>
                    <div class="vx-col sm:w-1/3 w-full">
                        <span class="text-primary font-bold">
                            {{ car.registrationExpiryDate | formatLongDate }}</span>
                        <vs-button v-show="!newDate" @click="compareRegistrationExpiry" class="ml-5">Validate</vs-button>
                    </div>
                </div>
                <div class="vx-row mb-2">
                    <div class="vx-col sm:w-1/3 w-full">
                        <span class="text-right">&nbsp;</span>
                    </div>
                    <div class="vx-col sm:w-1/3 w-full">
                        <p class="text-muted text-danger">Please click on Validate, to update your vehicle registration expiry date.</p>
                    </div>
                </div>
                <div v-show="newDate">
                    <div class="vx-row mb-2">
                        <div class="vx-col sm:w-1/3 w-full">
                            <span class="text-right">Updated Registration Expire:</span>
                        </div>
                        <div class="vx-col sm:w-1/3 w-full">
                            <span class="text-primary font-bold">
                                {{ registrationDetails.expiry_date | formatLongDate }}</span>

                            <vs-button @click="updateRegistrationDetails" class="ml-5">Update</vs-button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</vx-card>
</template>

<script>
import moment from "moment";

import VxCard from "../../components/vx-card/VxCard.vue";
import {
    carData
} from "../../store/api/car";

export default {
    props: {},
    watch: {},
    components: {
        VxCard
    },
    data() {
        return {
            car: {},
            isAgree: false,
            registrationDetails: {},
            newDate: false
        };
    },
    async created() {
        await this.getCarData();
    },

    methods: {
        async getCarData() {
            if (this.$route.params && this.$route.params.id) {
                this.car = await carData.SearchCarRegistrationById(
                    this.$route.params.id
                );
            }
        },
        async findCar() {
            this.registrationDetails = await carData.findCar(
                this.car.registrationPlate,
                this.car.registrationState
            );
        },

        async compareRegistrationExpiry() {
            await this.findCar();

            let compare = moment(this.registrationDetails.expiry_date).isSame(
                this.car.registrationExpiryDate
            );
            if (!compare) {
                this.$vs.notify({
                    text: "Expiry Date Updated",
                    color: "success",
                    position: "top-center"
                });
                this.newDate = true;
            } else {
                this.$vs.notify({
                    text: "Registration is Upto Date",
                    color: "success",
                    position: "top-center"
                });
            }
        },

        async updateRegistrationDetails() {
            const update = await carData.UpdateCarRegistrationDetails({
                id: this.car.id,
                model: this.car.model,
                registrationExpiryDate: this.registrationDetails.expiry_date,
                registrationPlate: this.car.registrationPlate
            });
            if (!update.succeeded) {
                this.$vs.notify({
                    title: "Error",
                    text: update.message,
                    color: "danger",
                    position: "top-center"
                });
                return;
            }
            this.$vs.notify({
                title: "Success",
                text: "Expiry Date Updated",
                color: "success",
                position: "top-center"
            });
        },
        goBack() {
            this.$router.push("/Vehicles");
        }
    }
};
</script>

<style lang="scss" scoped>
.card-overflow {
    overflow: overlay;
}

.text-right {
    float: right;
    margin-top: 5px;

    @media screen and (max-width: 1200px) {
        float: left;
    }
}
</style>
